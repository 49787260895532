.edit-root{
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  background: #ffffff !important;
  padding-bottom: 20px !important;
  margin-top: -40px !important;
  position: relative !important;
  right: -20px !important;
  padding: 30px 20px 50px !important;
}

@media (max-width : 1300px){
    .edit-root{
        margin-top: 10px !important;
        position: relative !important;
        background-color: #ffffff !important;
        right: 0 !important;
      }
}

