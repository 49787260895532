@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
    font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x:hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}
body {
  font-family: 'Open Sans', sans-serif;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
#root{
  background-color: #f7f9fc;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiOutlinedInput-root {
    float: right;
    position: relative;
      border-radius: 4px;
      margin-top: 8px;
      height: 36px;
      margin-right: 5px;
}

.MuiOutlinedInput-root {
    float: right;
    position: relative;
      border-radius: 4px;
      margin-top: 8px;
      height: 36px;
      margin-right: 5px;
}
.exp-item{
    box-shadow: 0px 4px 4px rgba(121, 115, 115, 0.25) !important;
    border-radius: 4px;
    padding: 20px !important;
    background-color: #ffffff !important;
    position: relative !important;
    margin-right:20px !important;
    margin-bottom: 10px !important;
}
.title-container{
   margin: 0px 0px 5px!important; 
}

.freelancer-profile > .avatar-uploader > .ant-upload {
    width: 200px;
    height: 200px;
    margin: auto;
}
.freelancer-profile > .ant-upload-picture-card-wrapper {
    zoom: 1;
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
}
.CPCI-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    max-width: 760px;
    margin: auto;
    margin-top: 60px;
    padding: 24px;
    border-radius: 5px;
}
.box-one-wrapper{
    display: flex;
    flex-direction: row;
}
.box-child{
    display: flex;
    flex-direction: column;
    min-width: 350px;
    padding: 10px;
}
.uplaud > .ant-upload-picture-card-wrapper > .ant-upload.ant-upload-select-picture-card {
    width: 120px !important;
    height: 120px !important;
  }
  .uplaux > .ant-upload-picture-card-wrapper > .ant-upload.ant-upload-select-picture-card {
    width: 100% !important;
    height: 120px !important;
  }
  .box-one-full{
      width: 100%;
  }
@media(max-width: 765px) {
    .box-one-wrapper{
        display: flex;
        flex-direction: column;
    }
}
.ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: center;
}
*{box-sizing:border-box;margin:0;padding:0}html{height:100%;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}body{background-color:#f4f6f8;height:100%;position:relative}a{text-decoration:none}#root{height:100%}

.MuiAppBar-colorPrimary-14 {
    color: #1b237d;
    background-color: #ffffff !important;
  }
  header{
    box-shadow: 0 0 0 1px rgba(93, 93, 99, 0.05), 0 1px 3px 0 rgba(63,63,68,0.15) !important;
  }
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.grid-container{
  width:100vw;
  margin-top: -40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #F6F8FB;
  box-sizing: border-box;
}

.profile-background {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin-left:-60px ;
  width: 110%;
  height: 200px;
  background: linear-gradient(92.36deg, #062C84 28.97%, #277DCE 104.18%) !important;
  position:relative !important;
}

.cards{
  display: flex !important;
  justify-content: center !important;
  align-items:center !important;
  margin-top: 15px !important;
  position: relative !important;
}

.userInfo{
margin-left: 20px !important;
margin-top: 35px !important;
position: relative !important;
width: inherit !important;
}

.profItem,.imageItem{
  position: relative;
  margin-left: 10px;
}

.userprofile{
font-family: "Roboto";
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
color: #F5F5F7;
text-transform: capitalize;
margin-top: 15px;
margin-left: 10px;
}

.info{
color: #ffffff !important;
font-family: "Roboto" !important;
font-style: normal !important;
font-weight: 500 !important;
font-size: 12px !important;
line-height: 16px !important;
margin-top: 10px !important;
margin-left: 10px !important;
margin-bottom: 8px !important;
letter-spacing: 0.6px !important;
}

.image,.firstLetter{
  width:100px !important;
  height: 100px !important;
  border-radius: 50% !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.firstLetter{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: azure !important;
  color:linear-gradient(92.36deg, #062C84 28.97%, #277DCE 104.18%) !important;
  font-size: 45px !important;
  font-weight: 600;
}

.row-2{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  position: relative;
  margin-top: 100px;
}

.grid-item {
  display: flex;
  margin: auto;
  flex-basis: 20%;
  width: 250px;
  overflow: hidden;
  position: relative;
  padding: 10px;
  box-sizing: border-box;
}
.job_card{
      margin-top: 20px;
      margin-left: 10px;
      padding: 15px 20px;
      height: 370px;
      position: relative;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
}

.less,.exp-less{
  position: absolute;
  top: 0px;
  right: 0px;
}
.exp-less{
  top: -35px;
  right: 0;
}

@media (max-width: 1333px) {
  .profile-background , .row-2 {
    margin-left: -20px;
    padding-bottom: 15px;
  }
.userInfo{
  margin-left: 20px;
}
 .image,.firstLetter{
    width:90px;
    height:90px;
  }
  
  .grid-item {
    flex-basis: 33.33%;
  }
  .cards{
    margin-top: 15px !important;
  }
}

@media (max-width : 768px){
  .profile-background , .row-2 {
    flex-basis: 100%;
  }
  .less{
    position: absolute;
    right: 0;
    top: -35px;
  }
}

.paper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    /* margin-top: 150px; */
    width: 302px;
    height: 95px;
    background-color: #FFFFFF;
    padding: 12px;
    padding-top: 24;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    position: relative;
  }
  .value {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
    background: #F2C94C;
    border-radius: 70px;
    width: 54px;
    height: 32px;
    position: absolute;
    right: 10px;
  }

@media (max-width : 1300px){
    .paper{
        margin-top: 20px;
        width: 212px;
        height: 60px;
    }
    .value{
    width: 34px;
    height: 16px;
    position: absolute;
    right: 5;
    }
}
.col-blue {
  background-color: #4da9ff;
  border-radius: 25px;
  padding: 0px 0px 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.col-green {
  background-color: #00d3a7;
  border-radius: 25px;
  padding: 0px 0px 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.col-yellow {
  background-color: #ffc000;
  border-radius: 25px;
  padding: 0px 0px 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.skill-tag-icons {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.skill-box {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
.select-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 24px;
  width: 100%;
}
@media (max-width: 636px) {
  .select-row {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 24px;
    height: 150px;
  }
  .skill-box {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
  }
  .col-blue {
    margin: 5px auto;
  }
  .col-green {
    margin: 5px auto;
  }
  .col-yellow {
    margin: 5px auto;
  }
}

.col-blue {
  background-color: #4da9ff;
  border-radius: 25px;
  padding: 0px 0px 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.col-green {
  background-color: #00d3a7;
  border-radius: 25px;
  padding: 0px 0px 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.col-yellow {
  background-color: #ffc000;
  border-radius: 25px;
  padding: 0px 0px 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.language-tag-icons {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.language-box {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
.select-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 24px;
  width: 100%;
}
@media (max-width: 636px) {
  .select-row {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 24px;
    height: 150px;
  }
  .language-box {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
  }
  .col-blue {
    margin: 5px auto;
  }
  .col-green {
    margin: 5px auto;
  }
  .col-yellow {
    margin: 5px auto;
  }
}

.col-blue {
  background-color: #4da9ff;
  border-radius: 25px;
  padding: 0px 0px 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.col-green {
  background-color: #00d3a7;
  border-radius: 25px;
  padding: 0px 0px 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.col-yellow {
  background-color: #ffc000;
  border-radius: 25px;
  padding: 0px 0px 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.interest-tag-icons {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.interest-box {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
.select-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 24px;
  width: 100%;
}
@media (max-width: 636px) {
  .select-row {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 24px;
    height: 150px;
  }
  .interest-box {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
  }
  .col-blue {
    margin: 5px auto;
  }
  .col-green {
    margin: 5px auto;
  }
  .col-yellow {
    margin: 5px auto;
  }
}

.field {
  display: flex;
  flex-direction: row;
}
.exp-item {
  margin-top: 24px;
  box-shadow: 0 6px 23px -9px black;
  padding: 15px;
}
.contain-experience {
  width: 500px;
  padding: 24px;
}
.field-item {
  padding: 5px;
}
.field-input {
  width: 380px;
}
#form-dialog-title {
  padding: 20px;
}
.shift-left {
  margin-left: -250px;
}
.shift-bit {
  margin-left: -155px;
}
.displayNot {
  display: none;
}

.ant-table table {
    width: 100%;
    text-align: left;
    border: 0.5px solid #8e8b8b61;
    border-radius: 7px;
    box-shadow: 0 0 0 1px #3f3f440d, 0 1px 3px 0 rgba(63,63,68,0.15);
    border-collapse: separate;
    border-spacing: 0;
    background-color: white;
    padding-left: 10px !important;
}
.clearButton{
    margin-right: 10px !important;
    margin-bottom: 15px !important;
  }
  .viewButton{
    background-color: #0b196a !important;
    border-color: #0b196a !important;
    color: #ffffff !important;
  }
  .viewButton-mob{
      display: none !important;
  }

.m_table{
    width: 500px !important;
}

@media (max-width : 768px){
    .viewButton{
        display: none !important;
    }
    .viewButton-mob{
        display: block !important;
        background-color: #0b196a !important;
        border-color: #0b196a !important;
    }
    .arrow-front{
        font-size: 12px !important;
        color: #ffffff !important;
    }
}

@media (max-width:1300px){
    .ant-table table {
        width: 100% !important;
        margin-left: 0px !important;
        padding-right:10px ;
    }    
    .tables{
        width: 100% !important;
        margin-left: 0px !important;
        padding-left: 0px !important;
    }
}

@media (max-width : 450px) {
    .viewButton-mob{
        display: block !important;
        width: 30px !important;
        /* height: fit-content !important; */
        border-color: #0b196a !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }
    .arrow-front{
        font-size: 10px !important;
        color: #ffffff !important;
    }
    }


/* Job info  */

.job_details {
  background-color: #FFFFFF !important;
  box-shadow:
    0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2) !important;
  padding: 0px 10px !important;
  border-radius: 5px !important;
  width: 100% !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  margin-top: -40px !important;
  margin-right: -10px !important;
}

.client_image,.letter_image{
  width: 100px !important;
  height: 100px !important;
  border-radius: 50% !important;
  background-color:gray !important;
  position: relative !important;
  border: 1px solid whitesmoke !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-origin: padding-box !important;
}

.letter_image{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 50px !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

@media (max-width : 1300px){
  .job_details{
    margin-top: -20px !important;
    margin-right: 0px !important;
  }
}


  
.container{
    display: flex !important;
    flex-direction: row !important;
    background-color: #FFFFFF !important;
    box-shadow:
      0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2) !important;
    padding: 15 !important;
    border-radius: 5 !important;
    margin: 20px !important;
    width: 100% !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
  }

.applied-root {
    min-width: 249px !important;
    height: 600px !important;
    overflow: scroll !important;
    max-width: 36ch !important;
    background-color: #FFFFFF !important;
  }

  .job-info-container{
      height: 600px;
      width: 100%;
      padding:5px 7px 10px;
      overflow: scroll;
      background-color: #FFFFFF !important;
  }

  @media (max-width : 768px) {
      .container{
        display: flex !important;
        background-color: unset !important;
        flex-direction: column !important;
        justify-content: space-between;
        margin: 0px !important;
        margin-top: -20px !important;
        width: 100% !important;
        height: -webkit-fit-content !important;
        height: -moz-fit-content !important;
        height: fit-content !important;
        box-shadow: unset !important;
      }
      .applied-root {
        min-width: 100% !important;
        padding: 10px !important;
        height: 600px !important;
        overflow-y: scroll !important;
        max-width: 36ch !important;
        box-shadow: 0px 0px 2px gray !important;
        display: flex !important;
        flex-wrap: wrap !important;
      }

      .job-info-container{
        margin-top: 50px;
        height: 600px;
        width: 100%;
        padding:5px 7px 10px;
        overflow: scroll;
        box-shadow: 0px 0px 2px gray !important;
    }
    .box-mobile{
      display: flex;
    }
  }

  
.formheading {
   margin-top: 10 !important;
      color: #0C0033 !important;
      text-align: center !important;
   overflow-wrap:break-word !important;
   width:600px !important;
 }
    
 @media (max-width : 800px) {
   .formheading {
       width:-webkit-fit-content !important;
       width:-moz-fit-content !important;
       width:fit-content !important;
     }
 }

 
company-logo-row > .ant-upload-picture-card-wrapper > .ant-upload.ant-upload-select-picture-card {
    width: 165px !important;
    height: 165px !important;
    border-radius: 100px;
  }
  .box-children{
    display: flex;
    flex-direction: column;
    min-width: 200px;
    padding: 10px;
} 


.edit-root{
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  background: #ffffff !important;
  padding-bottom: 20px !important;
  margin-top: -40px !important;
  position: relative !important;
  right: -20px !important;
  padding: 30px 20px 50px !important;
}

@media (max-width : 1300px){
    .edit-root{
        margin-top: 10px !important;
        position: relative !important;
        background-color: #ffffff !important;
        right: 0 !important;
      }
}


.root{
    width:100%;
    min-height: 100vh;
    flex-grow: 1;
    background-color: "#F6F8FB";
    padding: 14;
    position:relative;
    margin-top: "-20px";
  }
  .application_details{
    background-Color: #FFFFFF;
    /* box-Shadow:
      0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2); */
    /* padding:20px; */
    padding-bottom: 10px;
    border-radius: 5px;
    margin-top: -43px;
    width: 100%;
    min-height: 100vh;
    margin-right:-20px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
  }
  .boxContainer{
    padding: 5px 20px;
  }
@media (max-width : 1300px){
    .root{
        position: relative;
        top:unset;
        right: unset;
        width:100%;
    }
    .application_details{
        width: 100%;
        margin-top: 0;
        margin-right: 0;
        margin-left: 0;
    }
}

@media (min-width : 300px) and (max-width : 768px){
  .application_details{
    min-width: 100%;
  }
}

.list-root{
    position:relative !important;
    padding : 5px 0px !important;
  }

@media (min-width : 1300px){
    .list-root{
        padding: 7px 3px;
    }
} 


.question-item {
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 6px 23px -9px black;
}

.MuiTab-wrapper-440 {
  font-size: 5px;
  font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 25px;
color:'red';
}

.question-root {
  box-shadow: 0px 0px 2px lightgray !important;
  position:relative !important;
  width: 100% !important;
  min-height:100vh !important;
  margin-top : -40px !important;
  margin-right:-20px !important;
  background-color: #ffffff !important;
}

.mainSubtitle{
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: bold;
  color: #36A720 !important;
  position:absolute;
  line-height: 21px;
  font-size:14px;
  right:0;
  margin-top: 6px !important;
}

.tab{
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.tabItem1,.tabItem2{
  font-size: 12px !important;
  position: absolute !important;
  left: 0px !important;
}

@media (min-width : 40px) and (max-width:768px) {
  .tabItem1,.tabItem2{
    padding-right: 10px;
    font-size: 11px !important;
  }
 .tabItem2{
  margin-left: 0px !important; 
}
}

@media (max-width : 1300px){
  .question-root{
    margin:0 !important;
    margin-bottom: 20px !important;
  }
  .mainSubtitle {
    font-size: 14px;
    position: relative;
    right: 0;
    top: 0;
  }
} 


.ant-tag {
  border-radius: 25px;
}
.ant-card{
  border: none;
  background-color: transparent;
}
.ant-avatar{
  margin-top: -24px;
  height: 63px;
  width: 63px;
}
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  display: block;
  height: 100%;
  font-size: 12px;
  text-align: center;
  width: 100px;
}
.ant-table-body {
  transition: opacity 0.3s;
  max-width: 950px;
  background-color: white;
}

.applicant_table{
  width:95%
}
.job-app-table{
    max-width: -webkit-fit-content !important;
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
    display: flex;
    justify-content: center;
    margin: auto;
}
.job-detail{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 24px;
  margin-top: 0px;
}
.job-title{
    margin-top: 24px !important;
}

h2.ant-typography, .ant-typography h2 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  font-size: 30px;
  line-height: 1.35;
  margin-left: 20px;
}
h4.ant-typography, .ant-typography h4 {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  font-size: 20px;
  line-height: 0;
  margin-left: 12px;
  margin-top: -12px;
}
.ant-typography + h4.ant-typography {
  margin-top: 0;
}
.category-tag{
  margin-left: 12px;
  margin-top: 12px;
  border-radius: 4px;
}
.detail-root{
  background-color: #ffffff;
  margin-top: -40px !important;
  width:100%;
  height: 100vh !important;
  position: relative;
  right: -20px;
  padding: 20px 0px;
}

.mainContainer {
  padding : 15px 0px 30px 0px !important;
  display: flex;
  justify-content: space-around !important;
}

.buttons{
  display: flex;
}

.data,.loading{
  margin-top: 50% !important;
}

.loading{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
@media (max-width : 1250px){
  .detail-root {
    width:100vw;
    height: 100% !important;
    position:relative;
    right: unset !important;
    margin-top: 0px !important;
  }
  
  .applicant_table{
    width:100%
  }
} 

@media (max-width : 768px){
  .buttons{
    margin-top: 10px !important;
    margin-left: -30px !important;
  }
}


.CPJ-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  max-width: 760px;
  margin: auto;
  margin-top: 20px;
  padding: 24px;
  border-radius: 5px;
}
.box-child-job{
  display: flex;
  flex-direction: column;
  width: 350px;
  padding: 10px;
}
.select-box-row{
  display: flex;
  flex-direction: row;
}
.create-root {
  display:flex;
  flex-direction: column;
  margin-top:-40px !important;
  margin-left: 30px;
  width:100%;
  height:100% !important;
  background:#ffffff;
  padding: 30px 40px 60px !important;
}

.button {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap;
  float: right !important;
  justify-content:space-between !important;
}

.drafts {
  background-color: #3bc1a5 !important;
  float: left;
}

.cancel{
  background-color: black;
  opacity: 0.25;
  height: 36;
  width: 77;
  margin-right: 10px !important;
}

.create {
  height: 36;
  width: 133;
}

@media(max-width: 800px) {
  .select-box-row{
      display: flex;
      flex-direction: column;
  }
  .button{
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
  }
  .cancel{
    margin-right: 10px !important;
  } 
  .drafts{
    margin-bottom: 10px !important;
  }
}

@media (max-width : 1300px){
  .create-root {
    width : 100%;
    position: relative;
    right: unset;
    margin-left : 0 !important;
    margin-top: 20px !important;
  }
} 


.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    display: block;
    height: 100%;
    font-size: 12px;
    text-align: center;
    width: 30px;
    margin: auto;
}

.m_table{
    width : 100% !important;
    margin-left : -40px !important;
    margin-bottom : 50px !important;
    position: relative !important;
    padding: 10 !important;
}

.l_table{
width : 90% !important;
position: relative;
padding: 20px;
}

.tab{
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
}

.table-text{
    font-size: 14px;
  }

  .show{
    background-color: #0b196a;
    border-color: #0b196a;
    color: white;
    position: relative;
  }
  .show-mob{
      display: none !important;
  }

.ant-table table {
    width: 100% !important;
    text-align: left;
    border: 1px solid #8e8b8b61;
    border-radius: 7px;
    box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15) !important;
    border-collapse: separate !important;
    border-spacing: 0 !important;
    background-color: #ffffff;
    /* padding: 10px; */
    position: relative !important;
}
.filters {
    margin-left : 5px
}
  .buttonContainer{
      position: relative;
      margin-bottom: 5px;
  }

@media (max-width : 1300px) {
    .ant-table table{
        width:  100% !important;
        padding: 10px !important ;
    }

.m_table {
    width : -webkit-fit-content !important;
    width : -moz-fit-content !important;
    width : fit-content !important;
    margin-left : 0px !important;
    margin-bottom: 0 !important;
}

.buttonContainer{
    position: relative;
    margin-bottom: 8px;
}
.show-mob{
    display: block !important;
    background-color: #0b196a !important;
    border-color: #0b196a !important;
}
.arrow-front{
    color: #ffffff !important;
    border-color: #0b196a !important;
    font-size: 12px !important;
}
.show{
    display: none !important;
}
} 

@media (min-width : 300px) and (max-width:1000px){
    .filters {
        margin-left : 0px
    }
}


.col-blue{
    background-color: #4DA9FF;
    border-radius: 25px;
    padding: 0px 0px 0px 15px ;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}
.col-green{
    background-color: #00D3A7;
    border-radius: 25px;
    padding: 0px 0px 0px 15px ;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}
.col-yellow{
    background-color: #FFC000;
    border-radius: 25px;
    padding: 0px 0px 0px 15px ;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}
.skill-tag-icons{
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}
.skill-box{
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}
.select-row{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 24px;
    width: 100%;
}
@media (max-width: 636px) {
    .select-row{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-top: 24px;
        height: 150px;
    }
    .skill-box{
        margin-top: 24px;
        display: flex;
        flex-direction: column;
    }
    .col-blue{
       margin: 5px auto;
    }
    .col-green{
        margin: 5px auto;
    }
    .col-yellow{
        margin: 5px auto;
    }
  }
.col-blue{
    background-color: #4DA9FF;
    border-radius: 25px;
    padding: 0px 0px 0px 15px ;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}
.col-green{
    background-color: #00D3A7;
    border-radius: 25px;
    padding: 0px 0px 0px 15px ;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}
.col-yellow{
    background-color: #FFC000;
    border-radius: 25px;
    padding: 0px 0px 0px 15px ;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}
.interest-tag-icons{
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}
.interest-box{
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}
.select-row{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 24px;
    width: 100%;
}
@media (max-width: 636px) {
    .select-row{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-top: 24px;
        height: 150px;
    }
    .interest-box{
        margin-top: 24px;
        display: flex;
        flex-direction: column;
    }
    .col-blue{
       margin: 5px auto;
    }
    .col-green{
        margin: 5px auto;
    }
    .col-yellow{
        margin: 5px auto;
    }
  }
.field{
    display: flex;
    flex-direction: row;
    }
.exp-item{
    margin-top: 24px;
    box-shadow: 0 6px 23px -9px black;
    padding: 15px;
}
.contain-experience{
    width: 500px;
    padding: 24px;
}
.field-item{
    padding: 5px;
}
.field-input{
    width: 380px;
}
#form-dialog-title{
    padding:20px
}
.shift-left{
    margin-left: -250px;
}
.shift-bit{
    margin-left: -155px;
}
.displayNot{
    display: none;
}
