.ant-table table {
    width: 100%;
    text-align: left;
    border: 0.5px solid #8e8b8b61;
    border-radius: 7px;
    box-shadow: 0 0 0 1px #3f3f440d, 0 1px 3px 0 rgba(63,63,68,0.15);
    border-collapse: separate;
    border-spacing: 0;
    background-color: white;
    padding-left: 10px !important;
}
.clearButton{
    margin-right: 10px !important;
    margin-bottom: 15px !important;
  }
  .viewButton{
    background-color: #0b196a !important;
    border-color: #0b196a !important;
    color: #ffffff !important;
  }
  .viewButton-mob{
      display: none !important;
  }

.m_table{
    width: 500px !important;
}

@media (max-width : 768px){
    .viewButton{
        display: none !important;
    }
    .viewButton-mob{
        display: block !important;
        background-color: #0b196a !important;
        border-color: #0b196a !important;
    }
    .arrow-front{
        font-size: 12px !important;
        color: #ffffff !important;
    }
}

@media (max-width:1300px){
    .ant-table table {
        width: 100% !important;
        margin-left: 0px !important;
        padding-right:10px ;
    }    
    .tables{
        width: 100% !important;
        margin-left: 0px !important;
        padding-left: 0px !important;
    }
}

@media (max-width : 450px) {
    .viewButton-mob{
        display: block !important;
        width: 30px !important;
        /* height: fit-content !important; */
        border-color: #0b196a !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }
    .arrow-front{
        font-size: 10px !important;
        color: #ffffff !important;
    }
    }

