.paper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    /* margin-top: 150px; */
    width: 302px;
    height: 95px;
    background-color: #FFFFFF;
    padding: 12px;
    padding-top: 24;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    position: relative;
  }
  .value {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
    background: #F2C94C;
    border-radius: 70px;
    width: 54px;
    height: 32px;
    position: absolute;
    right: 10px;
  }

@media (max-width : 1300px){
    .paper{
        margin-top: 20px;
        width: 212px;
        height: 60px;
    }
    .value{
    width: 34px;
    height: 16px;
    position: absolute;
    right: 5;
    }
}