.exp-item{
    box-shadow: 0px 4px 4px rgba(121, 115, 115, 0.25) !important;
    border-radius: 4px;
    padding: 20px !important;
    background-color: #ffffff !important;
    position: relative !important;
    margin-right:20px !important;
    margin-bottom: 10px !important;
}
.title-container{
   margin: 0px 0px 5px!important; 
}
