/* Job info  */

.job_details {
  background-color: #FFFFFF !important;
  box-shadow:
    0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2) !important;
  padding: 0px 10px !important;
  border-radius: 5px !important;
  width: 100% !important;
  height: fit-content !important;
  margin-top: -40px !important;
  margin-right: -10px !important;
}

.client_image,.letter_image{
  width: 100px !important;
  height: 100px !important;
  border-radius: 50% !important;
  background-color:gray !important;
  position: relative !important;
  border: 1px solid whitesmoke !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-origin: padding-box !important;
}

.letter_image{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 50px !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

@media (max-width : 1300px){
  .job_details{
    margin-top: -20px !important;
    margin-right: 0px !important;
  }
}


  