.container{
    display: flex !important;
    flex-direction: row !important;
    background-color: #FFFFFF !important;
    box-shadow:
      0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2) !important;
    padding: 15 !important;
    border-radius: 5 !important;
    margin: 20px !important;
    width: 100% !important;
    height: fit-content !important;
  }

.applied-root {
    min-width: 249px !important;
    height: 600px !important;
    overflow: scroll !important;
    max-width: 36ch !important;
    background-color: #FFFFFF !important;
  }

  .job-info-container{
      height: 600px;
      width: 100%;
      padding:5px 7px 10px;
      overflow: scroll;
      background-color: #FFFFFF !important;
  }

  @media (max-width : 768px) {
      .container{
        display: flex !important;
        background-color: unset !important;
        flex-direction: column !important;
        justify-content: space-between;
        margin: 0px !important;
        margin-top: -20px !important;
        width: 100% !important;
        height: fit-content !important;
        box-shadow: unset !important;
      }
      .applied-root {
        min-width: 100% !important;
        padding: 10px !important;
        height: 600px !important;
        overflow-y: scroll !important;
        max-width: 36ch !important;
        box-shadow: 0px 0px 2px gray !important;
        display: flex !important;
        flex-wrap: wrap !important;
      }

      .job-info-container{
        margin-top: 50px;
        height: 600px;
        width: 100%;
        padding:5px 7px 10px;
        overflow: scroll;
        box-shadow: 0px 0px 2px gray !important;
    }
    .box-mobile{
      display: flex;
    }
  }

  