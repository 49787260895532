.question-item {
  -webkit-backdrop-filter: blur(10px);
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 6px 23px -9px black;
}

.MuiTab-wrapper-440 {
  font-size: 5px;
  font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 25px;
color:'red';
}

.question-root {
  box-shadow: 0px 0px 2px lightgray !important;
  position:relative !important;
  width: 100% !important;
  min-height:100vh !important;
  margin-top : -40px !important;
  margin-right:-20px !important;
  background-color: #ffffff !important;
}

.mainSubtitle{
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: bold;
  color: #36A720 !important;
  position:absolute;
  line-height: 21px;
  font-size:14px;
  right:0;
  margin-top: 6px !important;
}

.tab{
  width: fit-content !important;
}

.tabItem1,.tabItem2{
  font-size: 12px !important;
  position: absolute !important;
  left: 0px !important;
}

@media (min-width : 40px) and (max-width:768px) {
  .tabItem1,.tabItem2{
    padding-right: 10px;
    font-size: 11px !important;
  }
 .tabItem2{
  margin-left: 0px !important; 
}
}

@media (max-width : 1300px){
  .question-root{
    margin:0 !important;
    margin-bottom: 20px !important;
  }
  .mainSubtitle {
    font-size: 14px;
    position: relative;
    right: 0;
    top: 0;
  }
} 

