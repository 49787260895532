.formheading {
   margin-top: 10 !important;
      color: #0C0033 !important;
      text-align: center !important;
   overflow-wrap:break-word !important;
   width:600px !important;
 }
    
 @media (max-width : 800px) {
   .formheading {
       width:fit-content !important;
     }
 }

 