.field{
    display: flex;
    flex-direction: row;
    }
.exp-item{
    margin-top: 24px;
    box-shadow: 0 6px 23px -9px black;
    padding: 15px;
}
.contain-experience{
    width: 500px;
    padding: 24px;
}
.field-item{
    padding: 5px;
}
.field-input{
    width: 380px;
}
#form-dialog-title{
    padding:20px
}
.shift-left{
    margin-left: -250px;
}
.shift-bit{
    margin-left: -155px;
}
.displayNot{
    display: none;
}