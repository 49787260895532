company-logo-row > .ant-upload-picture-card-wrapper > .ant-upload.ant-upload-select-picture-card {
    width: 165px !important;
    height: 165px !important;
    border-radius: 100px;
  }
  .box-children{
    display: flex;
    flex-direction: column;
    min-width: 200px;
    padding: 10px;
} 

