*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.grid-container{
  width:100vw;
  margin-top: -40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #F6F8FB;
  box-sizing: border-box;
}

.profile-background {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin-left:-60px ;
  width: 110%;
  height: 200px;
  background: linear-gradient(92.36deg, #062C84 28.97%, #277DCE 104.18%) !important;
  position:relative !important;
}

.cards{
  display: flex !important;
  justify-content: center !important;
  align-items:center !important;
  margin-top: 15px !important;
  position: relative !important;
}

.userInfo{
margin-left: 20px !important;
margin-top: 35px !important;
position: relative !important;
width: inherit !important;
}

.profItem,.imageItem{
  position: relative;
  margin-left: 10px;
}

.userprofile{
font-family: "Roboto";
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
color: #F5F5F7;
text-transform: capitalize;
margin-top: 15px;
margin-left: 10px;
}

.info{
color: #ffffff !important;
font-family: "Roboto" !important;
font-style: normal !important;
font-weight: 500 !important;
font-size: 12px !important;
line-height: 16px !important;
margin-top: 10px !important;
margin-left: 10px !important;
margin-bottom: 8px !important;
letter-spacing: 0.6px !important;
}

.image,.firstLetter{
  width:100px !important;
  height: 100px !important;
  border-radius: 50% !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.firstLetter{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: azure !important;
  color:linear-gradient(92.36deg, #062C84 28.97%, #277DCE 104.18%) !important;
  font-size: 45px !important;
  font-weight: 600;
}

.row-2{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  position: relative;
  margin-top: 100px;
}

.grid-item {
  display: flex;
  margin: auto;
  flex-basis: 20%;
  width: 250px;
  overflow: hidden;
  position: relative;
  padding: 10px;
  box-sizing: border-box;
}
.job_card{
      margin-top: 20px;
      margin-left: 10px;
      padding: 15px 20px;
      height: 370px;
      position: relative;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
}

.less,.exp-less{
  position: absolute;
  top: 0px;
  right: 0px;
}
.exp-less{
  top: -35px;
  right: 0;
}

@media (max-width: 1333px) {
  .profile-background , .row-2 {
    margin-left: -20px;
    padding-bottom: 15px;
  }
.userInfo{
  margin-left: 20px;
}
 .image,.firstLetter{
    width:90px;
    height:90px;
  }
  
  .grid-item {
    flex-basis: 33.33%;
  }
  .cards{
    margin-top: 15px !important;
  }
}

@media (max-width : 768px){
  .profile-background , .row-2 {
    flex-basis: 100%;
  }
  .less{
    position: absolute;
    right: 0;
    top: -35px;
  }
}
