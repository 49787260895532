.root{
    width:100%;
    min-height: 100vh;
    flex-grow: 1;
    background-color: "#F6F8FB";
    padding: 14;
    position:relative;
    margin-top: "-20px";
  }
  .application_details{
    background-Color: #FFFFFF;
    /* box-Shadow:
      0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2); */
    /* padding:20px; */
    padding-bottom: 10px;
    border-radius: 5px;
    margin-top: -43px;
    width: 100%;
    min-height: 100vh;
    margin-right:-20px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
  }
  .boxContainer{
    padding: 5px 20px;
  }
@media (max-width : 1300px){
    .root{
        position: relative;
        top:unset;
        right: unset;
        width:100%;
    }
    .application_details{
        width: 100%;
        margin-top: 0;
        margin-right: 0;
        margin-left: 0;
    }
}

@media (min-width : 300px) and (max-width : 768px){
  .application_details{
    min-width: 100%;
  }
}
