.CPCI-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    max-width: 760px;
    margin: auto;
    margin-top: 60px;
    padding: 24px;
    border-radius: 5px;
}
.box-one-wrapper{
    display: flex;
    flex-direction: row;
}
.box-child{
    display: flex;
    flex-direction: column;
    min-width: 350px;
    padding: 10px;
}
.uplaud > .ant-upload-picture-card-wrapper > .ant-upload.ant-upload-select-picture-card {
    width: 120px !important;
    height: 120px !important;
  }
  .uplaux > .ant-upload-picture-card-wrapper > .ant-upload.ant-upload-select-picture-card {
    width: 100% !important;
    height: 120px !important;
  }
  .box-one-full{
      width: 100%;
  }
@media(max-width: 765px) {
    .box-one-wrapper{
        display: flex;
        flex-direction: column;
    }
}
.ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: center;
}