.col-blue {
  background-color: #4da9ff;
  border-radius: 25px;
  padding: 0px 0px 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
}
.col-green {
  background-color: #00d3a7;
  border-radius: 25px;
  padding: 0px 0px 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
}
.col-yellow {
  background-color: #ffc000;
  border-radius: 25px;
  padding: 0px 0px 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
}
.language-tag-icons {
  width: max-content;
}
.language-box {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
.select-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 24px;
  width: 100%;
}
@media (max-width: 636px) {
  .select-row {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 24px;
    height: 150px;
  }
  .language-box {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
  }
  .col-blue {
    margin: 5px auto;
  }
  .col-green {
    margin: 5px auto;
  }
  .col-yellow {
    margin: 5px auto;
  }
}
