.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    display: block;
    height: 100%;
    font-size: 12px;
    text-align: center;
    width: 30px;
    margin: auto;
}

.m_table{
    width : 100% !important;
    margin-left : -40px !important;
    margin-bottom : 50px !important;
    position: relative !important;
    padding: 10 !important;
}

.l_table{
width : 90% !important;
position: relative;
padding: 20px;
}

.tab{
    width: fit-content !important;
}

.table-text{
    font-size: 14px;
  }

  .show{
    background-color: #0b196a;
    border-color: #0b196a;
    color: white;
    position: relative;
  }
  .show-mob{
      display: none !important;
  }

.ant-table table {
    width: 100% !important;
    text-align: left;
    border: 1px solid #8e8b8b61;
    border-radius: 7px;
    box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15) !important;
    border-collapse: separate !important;
    border-spacing: 0 !important;
    background-color: #ffffff;
    /* padding: 10px; */
    position: relative !important;
}
.filters {
    margin-left : 5px
}
  .buttonContainer{
      position: relative;
      margin-bottom: 5px;
  }

@media (max-width : 1300px) {
    .ant-table table{
        width:  100% !important;
        padding: 10px !important ;
    }

.m_table {
    width : fit-content !important;
    margin-left : 0px !important;
    margin-bottom: 0 !important;
}

.buttonContainer{
    position: relative;
    margin-bottom: 8px;
}
.show-mob{
    display: block !important;
    background-color: #0b196a !important;
    border-color: #0b196a !important;
}
.arrow-front{
    color: #ffffff !important;
    border-color: #0b196a !important;
    font-size: 12px !important;
}
.show{
    display: none !important;
}
} 

@media (min-width : 300px) and (max-width:1000px){
    .filters {
        margin-left : 0px
    }
}

