.ant-tag {
  border-radius: 25px;
}
.ant-card{
  border: none;
  background-color: transparent;
}
.ant-avatar{
  margin-top: -24px;
  height: 63px;
  width: 63px;
}
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  display: block;
  height: 100%;
  font-size: 12px;
  text-align: center;
  width: 100px;
}
.ant-table-body {
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  max-width: 950px;
  background-color: white;
}

.applicant_table{
  width:95%
}
.job-app-table{
    max-width: fit-content !important;
    display: flex;
    justify-content: center;
    margin: auto;
}
.job-detail{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 24px;
  margin-top: 0px;
}
.job-title{
    margin-top: 24px !important;
}

h2.ant-typography, .ant-typography h2 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  font-size: 30px;
  line-height: 1.35;
  margin-left: 20px;
}
h4.ant-typography, .ant-typography h4 {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  font-size: 20px;
  line-height: 0;
  margin-left: 12px;
  margin-top: -12px;
}
.ant-typography + h4.ant-typography {
  margin-top: 0;
}
.category-tag{
  margin-left: 12px;
  margin-top: 12px;
  border-radius: 4px;
}
.detail-root{
  background-color: #ffffff;
  margin-top: -40px !important;
  width:100%;
  height: 100vh !important;
  position: relative;
  right: -20px;
  padding: 20px 0px;
}

.mainContainer {
  padding : 15px 0px 30px 0px !important;
  display: flex;
  justify-content: space-around !important;
}

.buttons{
  display: flex;
}

.data,.loading{
  margin-top: 50% !important;
}

.loading{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
@media (max-width : 1250px){
  .detail-root {
    width:100vw;
    height: 100% !important;
    position:relative;
    right: unset !important;
    margin-top: 0px !important;
  }
  
  .applicant_table{
    width:100%
  }
} 

@media (max-width : 768px){
  .buttons{
    margin-top: 10px !important;
    margin-left: -30px !important;
  }
}

