.CPJ-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  max-width: 760px;
  margin: auto;
  margin-top: 20px;
  padding: 24px;
  border-radius: 5px;
}
.box-child-job{
  display: flex;
  flex-direction: column;
  width: 350px;
  padding: 10px;
}
.select-box-row{
  display: flex;
  flex-direction: row;
}
.create-root {
  display:flex;
  flex-direction: column;
  margin-top:-40px !important;
  margin-left: 30px;
  width:100%;
  height:100% !important;
  background:#ffffff;
  padding: 30px 40px 60px !important;
}

.button {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap;
  float: right !important;
  justify-content:space-between !important;
}

.drafts {
  background-color: #3bc1a5 !important;
  float: left;
}

.cancel{
  background-color: black;
  opacity: 0.25;
  height: 36;
  width: 77;
  margin-right: 10px !important;
}

.create {
  height: 36;
  width: 133;
}

@media(max-width: 800px) {
  .select-box-row{
      display: flex;
      flex-direction: column;
  }
  .button{
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
  }
  .cancel{
    margin-right: 10px !important;
  } 
  .drafts{
    margin-bottom: 10px !important;
  }
}

@media (max-width : 1300px){
  .create-root {
    width : 100%;
    position: relative;
    right: unset;
    margin-left : 0 !important;
    margin-top: 20px !important;
  }
} 

