.list-root{
    position:relative !important;
    padding : 5px 0px !important;
  }

@media (min-width : 1300px){
    .list-root{
        padding: 7px 3px;
    }
} 

